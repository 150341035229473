import React, { useEffect, useRef, useState } from 'react';

const SearchFilter = ({ formSelector, searchWord, activeFilterType, counts }) => {
    const searchWordInputRef = useRef();
    const filterTypeInputRef = useRef();

    const handleFilterTagClick = (value) => {

        // Reset if applied filter type button is clicked again
        if(value === activeFilterType) {
            filterTypeInputRef.current.value = '';

        // Set filtered type if not yet applied
        } else {
            filterTypeInputRef.current.value = value;
        }

        document.querySelector(formSelector).submit();
    }

    const renderTag = (filterType, label) => (
        <li className="p-1">
            <button className="button is-primary has-text-weight-bold" onClick={() => handleFilterTagClick(filterType)}>
                {counts[filterType]} {label}
                {filterType == activeFilterType && <div className="close-icon"></div>}
            </button>
        </li>
    );

    return (
        <div className="search-filter">
            <input ref={searchWordInputRef} type="hidden" name="search_word" value={searchWord || ''} />
            <input ref={filterTypeInputRef} type="hidden" name="type" value={activeFilterType || ''} />
            <ul className="tags">
               {counts.articles > 0 && renderTag('articles', 'artikelen')}
               {counts.assignments > 0 && renderTag('assignments', 'opgaven')}
               {counts.contents > 0 && renderTag('contents', 'pagina\s')}
               {counts.movies > 0 && renderTag('movies', 'filmpjes')}
               {counts.applets > 0 && renderTag('applets', 'applets')}
               {counts.questions > 0 && renderTag('questions', 'vragen')}
            </ul>
        </div>
    );
};

export default SearchFilter;
